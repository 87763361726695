
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;450;500;600&display=swap');


* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}


:root {
  --color-bg: #27262e;
  --color-bg-variant: #1c1b22;
  
  --color-primary: #302d57;
  --color-primaryold: #2c2950;
  --color-primary-variant: #5f5aa4;

  --color-text: rgba(255, 255, 255, 0.629);
  --color-text-highlight: #f5f5f5;

  --button-text-color: #fff;

  --container-width-lg: 75%;
  --container-width-md: 90%;
  --container-width-sm: 94%;

  --border-radius: 0.4rem;
  --scale: 1.2;
  --dim: 70%;
  --brighten: 100%;
  --transition: transform .3s, filter .4s ease;

}


/* @media (prefers-color-scheme: light) {
  :root {
    --color-bg: #ebebeb;
    --color-bg-variant: #d0d0d0;
    
    --color-primary: #2c2950;
    --color-primary-variant: #5f5aa4;

    --color-text: #565656;
    --color-text-highlight: #272727;
  }

  .btn {
    color: #dcdcdc !important;
  }

  footer {
    color: var(--color-text);
  }

  .footer__socials a {
    background: var(--color-bg-variant);
    color: var(--color-primary);
    font-size: 2rem;
    border-radius: var(--border-radius);
    display: flex;
    transition: var(--transition);
    transform-origin: center center;
    filter: var(--dim);
  }

  @media screen and (max-width: 600px) {
    .nav-links a {
      background: transparent;
      padding: 0.9rem;
      border-radius: var(--border-radius);
      display: flex;
      color: var(--color-bg);
      font-size: 1.1rem;
    }
    
    .nav-links a:hover {
      background: var(--color-primary);
      color: #fff;
      border: .1rem solid var(--color-primary-variant);
    }
    
    .nav-links a.active {
      background: var(--color-primary);
      color: var(--color-bg);
      border: .1rem solid var(--color-primary-variant);
    }
  
    
  
    .navColorChange {
      background: rgba(0, 0, 0, 0.2);
    }
    
    .navColorChange .nav-links a.active {
      color: var(--color-bg);
    }
  }
} */


[data-theme='light'] {
  
  --color-bg: #e5e5e5;
  --color-bg-variant: #d0d0d0;
  
  --color-primary: #403b73;
  --color-primary-variant: #5f5aa4;

  --color-text: #565656;
  --color-text-highlight: #272727;
  

  /* .btn {
    color: #dcdcdc !important;
  }

  footer {
    color: var(--color-text);
  }

  .footer__socials a {
    background: var(--color-bg-variant);
    color: var(--color-primary);
    font-size: 2rem;
    border-radius: var(--border-radius);
    display: flex;
    transition: var(--transition);
    transform-origin: center center;
    filter: var(--dim);
  }

  @media screen and (max-width: 600px) {
    .nav-links a {
      background: transparent;
      padding: 0.9rem;
      border-radius: var(--border-radius);
      display: flex;
      color: var(--color-bg);
      font-size: 1.1rem;
    }
    
    .nav-links a:hover {
      background: var(--color-primary);
      color: #fff;
      border: .1rem solid var(--color-primary-variant);
    }
    
    .nav-links a.active {
      background: var(--color-primary);
      color: var(--color-bg);
      border: .1rem solid var(--color-primary-variant);
    }
  
    
  
    .navColorChange {
      background: rgba(0, 0, 0, 0.2);
    }
    
    .navColorChange .nav-links a.active {
      color: var(--color-bg);
    }
  } */
}

#underConstruction {
  display: flex; 
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* padding: 12rem 1rem; */
}



.App {
  background: var(--color-bg);
  color: var(--color-text);
  font-family: 'Montserrat', sans-serif;
  transition: background-color 250ms ease-in-out;
}

html {
  scroll-behavior: smooth;
  /* background: var(--color-bg);
  color: var(--color-text); */
}


::-webkit-scrollbar {
  display: none;
}


body {
  /* background: var(--color-bg) !important; */
  /* color: var(--color-text);
  font-family: 'Montserrat', sans-serif; */
  line-height: 1.7;
}

.blur {
  filter: blur(1rem) ;
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
  /* min-height: 60vh; */
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  /* background-color: var(--color-bg);
  background: var(--color-bg); */
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
   }
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}


h1 {
  font-size: 2.5rem;
}


section {
  /* margin-top: 8rem; */
  /* background-color: var(--color-bg); */
  min-height: 60vh;
  padding: 5rem;
}


section > h2, section > h5 {
  text-align: center;
  margin-bottom: 4rem;
}



section > h5 {
  margin-bottom: 4.4rem;
  text-transform: uppercase;
  font-weight: 600;
}




a {
  color: var(--color-text-highlight);
  transition: var(--transition);
}



.btn {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1rem;
  cursor: pointer;
  background: var(--color-primary);
  color: var(--button-text-color);
  border: .1rem solid var(--color-primary-variant);
  border-radius: var(--border-radius);
  transition: var(--transition);
  transform-origin: center center;
  /* filter: brightness(75%); */
}


.btn:hover {
  background: var(--color-primary-variant);
  /* filter: brightness(var(--brighten)); */
}


/* .btn-primary {
  background: var(--color-primary);
  color: var(--color-text-highlight);
} */



img {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}





/*** media quieries (medium devices) ***/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    /* margin-top: 6rem; */
    padding: 2rem;
    min-height: 60vh;
  }
}

/*** media quieries (small devices) ***/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2{
    margin-bottom: 2rem;
  }
}








